
.daily_dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
}
.blue {
    background-color: #9bbefd;
}
.gray {
    background-color: #9B9B9B;
}
.comparisons_chart_container {
    width: 100%;
    height: 330px;
    padding-top: 20px;
}
