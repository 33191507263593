.payment_overview_outgoing_transfer_success_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment_overview_outgoing_transfer_success_container > h2 {
    margin-top: 30px;
    font-size: 37px;
}

.payment_overview_outgoing_transfer_success_container > img {
    width: 80%;
    max-width: 170px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.payment_overview_outgoing_transfer_success_container > p {
    font-size: 20px;
    max-width: 400px;
    text-align: center;
    font-weight: 600;
}

.payment_overview_outgoing_transfer_success_container_title {
    font-size: 37px;
    color: #191818;
    text-align: center;
    padding-top: 20px;
}

.payment_overview_outgoing_transfer_success_container_box {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
}

.payment_overview_outgoing_transfer_success_container_box_title {
    color: #7C828A;
    font-size: 20px;
    font-weight: bold;
}

.payment_overview_outgoing_transfer_success_container_box_edit {
    font-size: 20px;
    font-weight: normal;
    color: #53B6F9;
    text-decoration: underline;
    cursor: pointer;
}

.payment_overview_outgoing_transfer_success_container_box_sub_title {
    font-size: 20px;
    color: #7C828A;
}

.payment_overview_outgoing_transfer_success_container_box_amount {
    font-size: 20px;
    color: #3D3D8E;
    font-weight: bold;
}

.payment_overview_outgoing_transfer_success_container_box_underline {
    width: 100%;
    height: 1px;
    background-color: #707070;
    margin-top: 20px;
    margin-bottom: 20px;
}
