.employeePaymentPage_not_activated_container {}


.employeePaymentPage_password-field {
	background: #fff;
	width: fit-content;
	max-width: 250px;
	width: 100%;
	display: flex;
	justify-content: center;
	border-radius: 4px;
	margin: 8px 0;
	padding: 6px 8px;
	position: relative;

}