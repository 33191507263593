/* STYLES FOR ALL TABLES */
.side_table_wrapper {
    width: 100%;
    max-width: 68vw;
}
.table_box {
    width: 100%;
}

.planlist th{
	padding-left: 1.3%
}

.table_wrapper {
    width: 100%;
    min-height: 100%;
}

.table_wrapper-overview{
    width: 100%;
    min-height: 100%;
}
.table_wrapper-overview th {
    text-align: left;
    padding-left: 1.3%;
    height: 75px;
    background-color: #DFDFEA;
    font-size: 12px;
    font-weight: 600;
    color: #222222;
}

.table_wrapper.overview th:first-child {
    border-radius: 5px 0 0 5px;
}


.table_wrapper th {
    text-align: left;
    height: 75px;
    padding-left: 1.3%;
    background-color: #DFDFEA;
    font-size: 12px;
    font-weight: 600;
    color: #222222;
}

@media (max-width:450px){
    .table_wrapper th {
        text-align: left;
        height: 75px;
        padding-left: 3px;
        background-color: #DFDFEA;
        font-size: 12px;
        font-weight: 600;
        color: #222222;
    }
}

th {
    text-align: left;
    padding: 0;
    font-size: 12px;
    background-color: #DFDFEA;
    height: 76px;
}

.table_wrapper th:first-child {
    border-radius: 5px 0 0 5px;
}
.table_wrapper th:last-child {
    border-radius: 0 5px 5px 0;
}
.spacer {
    height: 4px;
}
.table-item {
    height: 74px;
    background-color: #ffffff;
}

.dirty {
    background-color: #F5C6CB;
}

.blocked_item {
    height: 74px;
    background-color: #EEEEF3;
}

.blocked_item > td {
    color: #989898;
}

.permission_row {
    border-bottom: 1px solid rgba(12,32,53,0.1);
}

.list-triangle {
    position: relative;
    z-index: 0;
    font-size: 22px;
    cursor: pointer;
    margin-left: 10px;
    color: #C3C4D1;
}
.list-triangle.top {
    transform: rotateZ(-180deg) translateY(-10px);
}
.list-triangle.bottom {
    transform: translateY(-10px);
}
.list-triangle.selected {
    color: #000435;
}
/* PAGE TABLES FOR EMPLOYEES */
table.page-table td {
    padding-left: 0px;
}
table.page-table tr td:first-child {
    padding-left: 10px;
}

table.perm_table tr td {
    border: solid 1px #F3F3F3;
    border-style: none none solid none;
    font-weight: 500;
    color: #000000;
    font-size: 12px;
}


/* ONLY ONE WAY TO DO BORDER-RADIUS */
table { border-collapse: separate; border-spacing: 0; }
td {
    border: solid 1px #F3F3F3;
    border-style: none solid solid none;
    padding-left: 1.3%;
    font-weight: 500;
    color: #000000;
    font-size: 12px;
}

.selected_row {
    border: solid 1px rgba(116, 116, 147, 0.22);
    border-style: none solid solid none;
    border-top-style: solid;
    padding-left: 25px;
    font-weight: 500;
    color: #000000;
    font-size: 12px;
    height: 74px;
    background-color: rgba(118, 116, 147, 0.22);
}


tr td:first-child { border-bottom-left-radius: 5px; }
tr td:last-child { border-bottom-right-radius: 5px; }
tr td { border-top-style: solid; }
tr td:first-child { border-left-style: solid; }
tr td:not(:last-child) {border-right-style: hidden;}

/* Employee without App STYLES */
.employee_dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
}

td a {
    text-decoration: none;
    color: black;
}

.employee_table {
    width: 100%;
}
@media (max-width: 700px) {
    .employee_table {
        table-layout: fixed;
    }
}
/* Pending orders STYLES */
.action_dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #A2ADBF;
}
.action_container {
    cursor: pointer;
    max-width: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
}
.action_dots_container {
    position: relative;
}
.table_menu {
    position: absolute;
    min-width: 180px;
    min-height: 30px;
    background-color: white;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    right: 20px;
    top: 15px;
    z-index: 10!important;
}
.table_menu_item {
    height: 3rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 10px;
}
.table_menu_item > p {
    padding-left: 7px;
    font-size: 12px;
    font-weight: 600;
    color: #4B5960;
}
.table_menu_item > img {
    width: 17px;
    height: 17px;
}
.table_menu_item:not(:last-child) {
    border-bottom: 1px solid rgba(112,112,112 ,0.1);
}

.table-status {
    margin-top: 26px;
    color: #747578;
    font-size: 14px;
}

.notifications_list_item {
    width: 100%;
    height: 60px;
    border-radius: 3px;
    background-color: white;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    min-width: 400px;
}

.unread_notification_dot {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    background-color: red;
    margin-right: 10px;
}

.notification_item_image_box {
    position: relative;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification_item_image_box > img {
    width: 80px;
    margin-bottom: -10px;
}

.notification_item_name {
    font-size: 14px;
    padding-left: 10px;
    font-weight: 600;
    color: #1EC0A6;
}

.notification_item_text {
    padding-left: 10px;
    font-size: 12px;
}

.notification_item_date {
    font-size: 12px;
    color: #1EC0A6;
}

.notification_item_link {
    width: 15px;
    margin-left: 15px;
    cursor: pointer;
    transition: 0.2s;
}

.notification_item_link:hover {
    filter: sepia(100%) hue-rotate(190deg) saturate(500%) brightness(50%);
}

.billing_button {
    padding: 3px 7px;
    border: 1px solid #fd552b;
    color: #fd552b;
    border-radius: 5px;
    font-size: 10px;
}

.billing_list_btn {
    border: 1px solid #fd552b;
    color: #fd552b;
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

@media (max-width: 1400px) {
    .table_box {
        width: 100%;
        max-width: 86vw;
        overflow-x: auto;
    }
    .responsive-mr-40 {
        margin-right: 40px;
    }
    .notification_item_name {
        font-size: 12px;
        padding-left: 7px;
    }
    .notification_item_text {
        padding-left: 7px;
        font-size: 10px;
    }
    .notification_item_date {
        font-size: 10px;
        padding-left: 7px;
    }
}

