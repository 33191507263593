.filtering_container {
	cursor: pointer
}
.filtering_block {
    width: 100%;
    height: 100%;
    min-height: 60px;
    padding: 10px;
    background-color: #D7D7DE;
    margin-top: 1rem;
    border-radius: 7px;
    margin-bottom: 30px!important;
}
.filtering_title > img {
    margin-right: 1rem;
}

.filters_item {
    width: 100%;
    margin-bottom: 10px;
}

.filtering_text {
    font-size: 0.9rem;
    font-weight: 600;
}
@media (max-width: 600px) {
    .filtering_text {
        max-width: 120px;
    }
}
.filter_label {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 0.1rem;
}
.space_between_filter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

@media (max-width: 1200px) {
    .space_between_filter {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 920px) {
    .space_between_filter {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 720px) {
    .space_between_filter {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    .space_between_filter {
        grid-template-columns: 1fr;
    }
}


