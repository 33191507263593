.list_feedback_container {
    width: 100%;
    height: 50px;
    background-color: #EAEAEF;
    border-radius: 3px;
    margin-bottom: 10px;
    padding-left: 15px;
    display: flex;
    align-items: center;
}
.list_feedback_text {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    color: #4B5960;
    font-weight: 500;
}
.list_feedback_span {
    padding: 0;
    margin-left: 10px;
    font-size: 0.8rem;
    color: rgb(107, 148, 215);
    font-weight: 600;
    cursor: pointer;
}
.list_feed_loader {
    height: 100%;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}
