.welcome_left {
    position: relative;
    width: 100%;
    height: 100%;
}

.welcome_image {
    position: absolute;
    max-width: 100%;
    max-height: 115%;
    left: 10px;
    bottom: 0;
}
.welcome_right {
    padding: 32px 23px 27px 10px;
}
.progress_text {
    width: 100%;
    padding-bottom: 10px;
    text-align: center;
}
.progress_text > h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #ffffff;
}
.progress_text > p {
    font-size: 12px;
    max-width: 260px;
    font-weight: 300;
    color: rgba(255,255,255,0.66);
    margin-bottom: 10px;
}

.welcome_circle {
    width: 150px;
    height: 150px;
}

@media (max-width: 600px) {
    .progress_text > h2 {
        text-align: left;
    }
}
.welcome_dash {
    background-image: url("../../img/welcome_card.png");
    background-position: center;
    background-size: 140% 140%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
}

@media (max-width: 1100px) {
    .welcome_dash {
        grid-template-columns: 1fr;
    }
}


.welcome_dash_text {
    font-size: 14px;
    color: rgba(255,255,255,0.66);
}

@media (max-width: 1330px) {
    .welcome_circle {
        padding-top: 10px;
    }
}

@media (max-width: 1050px) {
    .welcome_dash {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 850px) {
    .welcome_dash {
        margin-bottom: 40px;
    }
}
@media (max-width: 500px) {
    .welcome_circle {
        max-width: 75%;
    }
    .progress_text {
        padding-top: 7px;
        width: 75%;
        margin-left: 10px;
        margin-right: 10px;
    }
}
