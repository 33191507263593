.card_creation_loading_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.card_creation_loading_container > img {
    width: 80%;
    max-width: 400px;
}

.card_creation_loading_container > h6 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 700;
}
