.tooltip_container {
    position: relative;
	display: inline-flex
}

.tooltip_box {
    position: absolute;
    padding: 5px;
    background-color: rgba(0,0,0,0.7);
    border-radius: 5px;
    max-width: 200px;
    min-width: 70px;
}

.tooltip_box > p {
    font-size: 10px;
    color: white;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0,0,0,0.7);
    position: absolute;
    top: -5px;
    left: calc(50% - 5px);
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0,0,0,0.7);
    position: absolute;
    bottom: -5px;
}
