main {
    width: 100%;
    min-height: 100%;
    padding: 20px;
    background-color: #F2F2F4;
}

@media (max-width: 1250px) {
    main {
        width: 100%;
        min-height: 100%;
        padding: 15px;
        background-color: #F2F2F4;
    }
}

@media (max-width: 600px) {
    main {
        width: 100%;
        min-height: 100%;
        padding: 13px;
        background-color: #F2F2F4;
    }
}
