.permission_avatar {
    width: 45px;
    min-width: 45px;
    height: 45px;
    min-height: 45px;
    border-radius: 50%;
    margin-right: 1rem;
    background-size: cover;
}
.permission_card_title {
    color: rgba(0,0,0,0.85);
    font-size: 0.9rem;
    margin-bottom: 2px;
    position: relative;
}
.permission_card_text {
    color: rgba(0,0,0,0.5);
}
.permission_card_title_hide {
    opacity: 0;
}
.permission_card_text_hide {
    opacity: 0;
}
.permission_top_row {
    display: flex;
    align-items: flex-start;
}
.permission_settings_icon {
    position: absolute;
    cursor: pointer;
    left: -2rem;
    top: 0.2rem;
    z-index: 99;
}
.permission_table_footer {
    width: 100%;
    height: 74px;
    background-color: rgba(159, 172, 199, 0.15);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
}
.permission_footer_text {
    color: rgba(145, 145, 160, 1);
}
.permissions_select_box {
    position: absolute;
    z-index: 100;
    top: -10px;
}

@media (max-width: 600px){
    .permissions_select_box{
        right:-10px;
        z-index: 2000;
    }
}



.permission_menu_box {
    position: relative;
    margin-left: 25px;
}

.permission_info_icon {
    display: none;
}
@media(max-width:600px){
    .permission_info_icon{
        right: 0px;
    }
}

.tooltip .tooltiptext {
    display: none;
}


@media (max-width: 540px) {
    .permission_menu_box > p {
        display: none;
    }
    .permission_info_icon {
        display: block;
        width: 15px;
        height: 15px;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        top: -5px;
    }
    .tooltip .tooltiptext {
        display: inline;
        visibility: hidden;
        width: 120px;
        background-color: #ececf1;
        font-size: 7px;
        color: #9192A0;
        text-align: left;
        padding: 4px;
        border-radius: 3px;
        position: absolute;
        left: 15px;
        z-index: 2;
    }
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
}
@media (max-width: 480px) {
    .tooltip .tooltiptext {
        top: 22px;
        left: -100px;
    }
}

.space-between_perm {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 450px) {
    .space-between_perm {
        display: block;
        margin-bottom: 15px;
    }
}


