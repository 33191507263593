.plans_container {
    margin-bottom: 20px;
}
.plans_container_header {
    width: 100%;
    height: 73px;
    background-color: white;
    border-radius: 5px;
    padding: 10px 20px;
}
.plans_close {
    padding-left: 12px;
    font-size: 16px;
    font-weight: 600;
    color: #A2ADBF;
}
.plans_container_main {
    background-color: white;
    width: 100%;
    padding: 3rem 20px 1.5rem 20px;
    border-radius: 5px;
    position: relative;
}
.plans_title {
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;
    font-family: Montserrat, sans-serif;
    color: black;
}
.plans_text {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
    line-height: 38px;
    padding-bottom: 20px;
}
.plans_success_wrapper {
    max-width: 600px;
}
.plans_success_wrapper > p {
    font-size: 0.8rem;
}
.plans_success_wrapper > h3 {
    font-size: 1rem;
}
.plans_success_title {
    font-weight: 600;
    font-size: 1.4rem;
}
.plans_success_box {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #CDE1EB;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
}
.plans_success_img {
    position: absolute;
    bottom: 0;
    left: 5%;
}
@media (max-width: 1000px) {
    .plans_success_img {
        max-width: 20%;
    }
}
@media (max-width: 600px) {
    .plans_success_img {
        display: none;
    }
}
