.simple_input.phoneNumber {
    padding-left: 60px;
}

.phoneNumber_flag {
    position: absolute;
    display: flex;
    align-items: center;
    left: 10px;
    cursor: pointer;
    padding-top: 18px;
}

.phoneNumber_flag > img {
    width: 9px;
    height: 9px;
    margin-left: 5px;
    transform: rotateZ(180deg);
}

.phone_number_input_dropdown {
    position: absolute;
    width: 100%;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 16px 7px rgba(0,0,0,0.13);
    -moz-box-shadow: 0 0 16px 7px rgba(0,0,0,0.13);
    box-shadow: 0 0 16px 7px rgba(0,0,0,0.13);
    border-radius: 10px;
    top: 80px;
    z-index: 2;
}

.phone_number_input_dropdown_item {
    padding: 7px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.phone_number_input_dropdown_item:first-child {
    border-radius: 10px 10px 0 0;
    padding-top: 14px;
}

.phone_number_input_dropdown_item:last-child {
    border-radius: 0 0 10px 10px;
    padding-bottom: 14px;
}

.phone_number_input_dropdown_item:hover {
    background-color: rgba(13, 10, 61, 0.11);
}

.phone_number_input_dropdown_item > p:first-child {
    margin-right: 7px;
}

.input_error.phoneNumber {
    bottom: -5px;
}
