.card_detail_top_section_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 37% 1fr;
    grid-gap: 32px;
}

.card_detail_top_section_container.info > h4 {
    font-size: 17px;
    font-weight: bold;
    color: #3D3D8E;
}

.card_detail_top_section_container.info > h5 {
    font-size: 16px;
    font-weight: normal;
    color: #666666;
    margin-top: 10px;
    margin-bottom: 30px;
}

.card_detail_top_section_container.info > p {
    font-size: 14px;
    font-weight: normal;
    color: #707070;
    margin-bottom: 16px;
}

.card_detail_top_section_info_container {
    margin-left: 12px;
    width: calc(100% - 12px);
    padding: 16px;
    background-color: #F3F3F3;
    display: grid;
    grid-template-columns: 62px 1fr;
    align-items: center;
    margin-bottom: 16px;
}

.card_detail_top_section_info_container > div > p {
    font-size: 14px;
    font-weight: normal;
    color: #707070;
}

.card_detail_top_section_container {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 3px;
    border: 1px dashed #8F9BB3;
}

.card_detail_top_section_container_title {
    font-size: 18px;
    color: #0D0A3D;
    margin-bottom: 7px;
}

.card_detail_top_section_container_label_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
}

.card_detail_top_section_container_label {
    font-size: 18px;
    color: #0D0A3D;
    margin-bottom: 7px;
    position: relative;
}

.card_detail_top_section_container_label:after {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #8AC142;
    z-index: 1;
    margin-left: 5px;
    top: 4px;
}

.card_detail_top_section_container_label.small:after {
    width: 10px;
    height: 10px;
}

.card_detail_top_section_container_label.supermarket:after {
    background-color: #946EC6;
}

.daily_chart_top_section_container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.daily_chart_top_section_container_chart {
    width: 340px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
}
