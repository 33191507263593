.error_page {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 150px);
    background-color: #ECECEF;
}
.error_page_box {
    position: relative;
    width: 70vw;
    padding-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}
.error_type {
    position: absolute;
    left: 6vw;
    top: 15vh;
    font-size: 30vw;
    color: #e3e3e9;
}
.error_page_title {
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: 3rem;
}
.error_page_text {
    padding-top: 2rem;
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}
.error_page_box > div {
    position: relative;
    z-index: 2;
    margin-top: 3rem;
}
.error_btn {
    max-width: 305px;
    margin-top: 5vw!important;
    margin-left: auto;
    margin-right: auto;
}
