.payment_overview_header_container_tab_content_container_cards_container {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
}

.payment_overview_header_container_tab_content_container_card {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    -moz-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    min-height: 120px;
}

.payment_overview_header_container_tab_content_container_card_grid {
    display: flex;
    justify-content: space-between;
}

.payment_overview_header_container_tab_content_container_card_icon_container {
    width: 60px;
    height: 60px;
    border-radius: 20px;
    background-color: #F6F6FA;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment_overview_header_container_tab_content_container_card_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.payment_overview_header_container_tab_content_container_card_content > h6 {
    font-size: 16px;
    font-weight: bolder;
    color: #767493;
    margin-bottom: 18px;
}

.payment_overview_header_container_tab_content_container_card_content > h5 {
    font-size: 30px;
    font-weight: bold;
    color: #0E0A40;
}

@media (max-width: 1300px) {
    .payment_overview_header_container_tab_content_container_cards_container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1187px) {
    .payment_overview_header_container_tab_content_container_cards_container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 860px) {
    .payment_overview_header_container_tab_content_container_cards_container {
        grid-template-columns: 1fr 1fr;
    }
}
