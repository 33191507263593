.leaving_page_modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leaving_page_modal > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    gap: 20px;
}
