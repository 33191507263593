.summary_container {
    width: 100%;
    margin-left: 15px;
    transform: translateY(-65px);
}
@media (max-width: 800px) {
    .plans_container {
        max-width: 87vw;
    }
    .summary_container {
        max-width: 87vw;
        margin-left: 0;
    }
}
.benefit_summary_title {
    font-size: 1.3rem;
    color: #0E0A40;
    margin-bottom: 29px;
}
.summary_count_text {
    font-size: 0.9rem;
    font-weight: 700;
}
.summary_total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.summary_total > h2 {
    font-size: 1rem;
}
