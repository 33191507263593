.general_drop_box {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    background-color: #D6D6DF;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
}
.general_drop_box_open {
    width: 100%;
    border-radius: 5px;
    background-color: #D6D6DF;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    padding-bottom: 10px;
}
.space-between_drop {
    width: 100%;
    height: 100%;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.general_drop_plus {
    margin-right: 1rem;
    width: 15px;
    height: 15px;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
}
.general_line_one {
    width: 100%;
    min-height: 3px;
    height: 3px;
    background-color: black;
    position: absolute;
    left: 0;
    top: calc(50% - 3px);
}
.general_line_two {
    width: 100%;
    height: 3px;
    min-height: 3px;
    background-color: black;
    position: absolute;
    left: 0;
    top: calc(50% - 3px);
    transform: rotateZ(90deg);
    transition: 0.3s;
}
.general_line_two_clicked {
    width: 100%;
    height: 3px;
    background-color: black;
    position: absolute;
    left: 0;
    top: calc(50% - 3px);
    transform: rotateZ(0);
    transition: 0.3s;
}
.drop_data > p {
    font-size: 0.8rem;
    color: #4a4747;
    font-weight: 600;
    margin-bottom: 10px;
}
.drop_data > h4 {
    font-size: 1.1rem;
    font-weight: 600;
}
.general_drop_grid {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: grid;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
}
.general_item_box {
    padding: 10px 7px;
    background-color: #BABAC6;
    margin-right: 2rem;
    border-radius: 5px;
    transition: 0.3s;
}

.branch_input {
    border: none;
    background-color: white;
    height: 28px;
    padding-left: 5px;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    color: #292452;
}

