.general_info_supermarket_warning_modal {
    width: 100%;
    max-width: 800px;
    min-width: 600px;
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.general_info_supermarket_warning_modal > h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}

.general_info_supermarket_warning_modal > p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
}
