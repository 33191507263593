.update_info_container {
    width: 100%;
    height: 100%;
    position: relative;
}

.update_info_hint {
    font-size: 11px;
    display: none;
    position: absolute;
    z-index: 2;
    width: max-content;
    min-width: 150px;
    min-height: 90px;
    bottom: 40px;
    right: 0;
    background-color: white;
    border-radius: 5px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.3) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.3) 0 8px 24px;
    padding: 20px;
}

@media (max-width: 1050px) {
    .update_info_hint {
        bottom: -100px;
        right: 100px;
    }
}

.update_info_container:hover .update_info_hint {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.update_info_grid {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    width: max-content;
}

.update_info_icon {
    width: 40px;
}

.update_info_title {
    font-size: 12px;
    font-weight: 600;
    color: #A3A6B4;
    width: 170px;
}

.update_info_text {
    margin-left: 7px;
    font-size: 12px;
    font-weight: 600;
    color: #707070;
}

@media (max-width: 1400px) {
    .update_info_hint {
        top: -50%;
        right: 110px;
        min-height: 220px;
    }
}
