.lunch_underlined {
    width: 100%;
    border-bottom: 1px solid rgba(159, 172, 199, 0.4);
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plans_form_title {
    font-size: 1rem;
    font-weight: 700;
    color: rgba(0,0,0,0.8);
}

.lunch_underlined > div > p {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    max-width: 600px;
    line-height: 1.5rem;
}

.plan_name {
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
}

@media (max-width: 600px) {
    .lunch_underlined > div > p {
        font-size: 11px;
    }
}

.plans_form_line {
    margin-left: 20px;
    width: 100%;
    height: 1px;
    background-color: rgba(159, 172, 199, 0.4);
}

.lunch_form_row {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 265px;
}
.small {
    min-width: 100px;
    max-width: 190px;
}
@media (max-width:450px){
    .small {
        min-width: 100px;
        max-width: 400px;
    }
}
.lunch_form_dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #4B4B4B;
    margin-right: 24px;
}
.lunch_form_text {
    font-size: 16px;
    color: rgba(0,0,0,0.8);
    margin-bottom: 0;
    margin-right: 24px;
    min-width: 150px;
}
.lunch_form_select_box {
    width: 100%;
    height: 50px;
    max-width: 350px;
}
.lunch_form_input {
    -webkit-appearance: none;
    width: 100%;
    max-width: 176px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #CDE1EB;
    padding-left: 10px;
    font-size: 16px;
}


.chosen_date_block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 1500px) {
    .chosen_date_block {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 1000px) {
    .chosen_date_block {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 600px) {
    .chosen_date_block {
        grid-template-columns: 1fr;
    }
}

.plans_list_wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
}
.edit_btn_text {
    padding-left: 7px;
    font-size: 14px;
    font-weight: 600;
    color: #767493;
}

.plans_item_box {
    display: flex;
    align-items: center;
    max-width: 60vw;
    overflow-x: auto;
}


.plan_delete_button {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 7px;
    border: 1px solid rgba(118, 116, 147, 0.34);
    background-color: #9FACC7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    margin-top: 6px;
    cursor: pointer;
}

.plans_item_container {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.selected_dates_container {
    margin-right: 10px;
    height: 40px;
    min-width: 120px;
    border: 1px solid rgba(14, 10, 64, 0.15);
    margin-top: 17px;
    border-radius: 5px;
    padding: 3px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2px;
    box-sizing: border-box;
}

.selected_date_box {
    width: 100%;
    background-color: rgba(14, 10, 64, 0.15);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    font-weight: 600;
}

.selected_date_box.unselected {
    opacity: 0.3;
}
