div.btn.secondary.icon img,
div.btn.primary.icon img {
    margin-top: -15px;
    display: inline;
}

div.btn.secondary.icon p {
    margin-right: 20px;
    color: #292452;
    display: inline-block;
    vertical-align: top;
}

div.btn.primary.icon p {
    margin-right: 20px;
    color: #ffffff;
    display: inline-block;
    vertical-align: top;
}
div.btn.success.icon p {
    color: #ffffff;
    display: inline-block;
    vertical-align: top;
}

div.btn.success.icon img {
    margin-top: -2px;
    margin-right: 10px;
}

.disabled {
    opacity: 0.25;
    cursor: not-allowed;
}

.add_branch_btn {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
}

.btn > div > p {
    font-weight: 600;
    font-size: 16px;
}
