.slider {
    margin-bottom: 40px;
}

.filter_range_title {
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.input-range__slider {
    background: #ffffff!important;
    border: 1px solid #D7D7DE!important;
}

.input-range__track {
    background: #ddd!important;
}

.input-range__track--active {
    background: #A7A7C3!important;


}

.input-range__label--value {
    display: none;
}

.input-range__label-container {
    display: none;
}

.filter_range_input-box {
    max-width: 47%;
}
.filter_range_input-box > label {
    font-size: 0.8rem;
    font-weight: 600;
}
.filter-range_input {
    margin-top: 0.5rem;
    max-width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #D7D7DE;
    padding-left: 1rem;
    font-size: 1.5rem;
}


