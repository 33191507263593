.simple_input {
    display: flex;
    flex-direction: column;
}

.simple_label {
    font-size: 12px;
    font-weight: 500;
    line-height: 27px;
    color: #0E0A40;
}

@media (max-width: 400px){
    .simple_label {
        font-size: 12px;
        font-weight: 500;
        margin-left: 5px;
        margin-top: 0em;
        color: #0E0A40;
    }
}

.simple_input input, select.simple_input {
    font-family: 'Montserrat', sans-serif;
    -webkit-appearance: none;
    margin-top: 5px;
    height: 42px;
    border: 1px solid rgba(14, 10, 64, 0.15);
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 15px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #37454D;
}
@media (max-width: 400px){
    .simple_input {
        display: flex;
        flex-direction: column;
        padding: 0%;
    }
}




.csv_upload_input {
    padding-left: 5px;
    font-family: 'Montserrat', sans-serif;
    -webkit-appearance: none;
    height: 30px;
    border: 1px solid rgba(14, 10, 64, 0.15);
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #37454D;
}

input:hover {
    border: 1.5px solid rgba(14, 10, 64, 1);
}

select.simple_input:hover {
    border: 1.5px solid rgba(14, 10, 64, 1);
}


input:focus {
    border: 2px solid #0062FF;
}

.search_container input:focus {
    border: none;
}
.search_container input:hover {
    border: none;
}

.simple_input:disabled {
    background-color: #f1f1f5;
    height: 40px;
}

.simple_input:disabled::placeholder {
    color: rgba(0, 0, 0, 0.5);
}

.simple_input input::placeholder, select.simple_input {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9aa9b3;
}

textarea::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9aa9b3;
}

select.simple_input {
    padding-top: 12px;
    background: url(../../img/select-arrow.svg) no-repeat right 1rem center;
    background-color: #ffffff;
}

.expand.simple_input {
    background: #D6D6DF url(../../img/icon-plus.svg) no-repeat right;
}

.collapse.simple_input {
    background: #D6D6DF url(../../img/icon-minus.svg) no-repeat right;
}
