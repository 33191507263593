@import url('https://fonts.googleapis.com/css?family=Overpass+Mono');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');

.card {
	margin-top: 20px;
	width: 356px;
	height: 234px;
	border-radius: 15px;
	position: relative;
}

.card_amount {
	font-size: 18px;
	color: #ffffff;
	font-weight: bold;
	position: absolute;
	right: 20px;
	top: 40px;
}

.card_number {
	font-family: "Overpass Mono", monospaced;
	color: #FFFFFF;
	position: absolute;
	font-size: 12px;
	bottom: 60px;
	left: 20px;
	min-width: 180px;
}

.card_expiry {
	font-family: "Overpass Mono", monospaced;
	color: #FFFFFF;
	position: absolute;
	font-size: 12px;
	bottom: 20px;
	left: 20px;
	width: 40px;
}

.card_cvv {
	font-family: "Overpass Mono", monospaced;
	color: #FFFFFF;
	position: absolute;
	font-size: 12px;
	bottom: 50px;
	right: 20px;
	width: 40px;
	text-align: right;
}

.card_type {
	font-family: "Overpass Mono", monospaced;
	color: #a9a9a9;
	position: absolute;
	font-size: 12px;
	top: 20px;
	right: 20px;
	text-transform: uppercase;
	display: flex
}

.card_icon {
	width: 15px;
	margin-right: 8px
}