.payment_overview_header_container_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment_overview_header_container_tab_content_container {
    padding-top: 20px;
    padding-bottom: 20px;
}

.payment_overview_header_container_tab_content_container_title {
    font-size: 25px;
    font-weight: bold;
    color: #3D3D8E;
}

.payment_overview_header_container_tab_content_container_sub_title {
    font-size: 18px;
    font-weight: normal;
    color: #0D0A3D;
}

.payment_overview_header_container_tab_content_container_cancel_btn {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 18px;
    color: #0D0A3D;
    cursor: pointer;
}
