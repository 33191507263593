.dep_branch_container {
    margin-top: 40px;
    margin-bottom: 30px;
}

.dep_branch_container > p {
    font-size: 14px;
}

.branch_dev_box {
    padding-top: 20px;
}

.general_half_ratio {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

@media (max-width: 800px) {
    .general_half_ratio {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
    }
}
