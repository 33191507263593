.multiple_invitation_modal_title {
    font-size: 18px;
}

.multiple_invitation_modal_text {
    font-size: 14px;
}

.selection_grid {
    max-width: 600px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.selection_grid_mail {
    font-size: 12px;
    background-color: #95e0fd;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 7px;
    margin-bottom: 7px;
}
