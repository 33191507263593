.purple-container {
    padding-top: 22px;
    padding-bottom: 24px;
    background: rgb(14,0,247);
    background: linear-gradient(0deg, rgba(14,0,247,0.0844712885154062) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(bottom,rgba(14,0,247,0.1),rgba(255,255,255,0.3) 55%,rgba(255,255,255,0));
}

@media (max-width: 400px){
    .purple-container {
        width: fit-content;
        padding-top: 22px;
        padding-bottom: 24px;
        background: rgb(14,0,247);
        background: linear-gradient(0deg, rgba(14,0,247,0.0844712885154062) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(bottom,rgba(14,0,247,0.1),rgba(255,255,255,0.3) 55%,rgba(255,255,255,0));
    }
}