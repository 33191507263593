.selected_table_actions {
    display: flex;
    align-items: center;
    position: relative;
}
.selected_table_actions > p {
    font-size: 0.9rem;
    color: rgba(142, 148, 153, 0.9);
    padding-right: 0.5rem;
    font-weight: 500;
}
.selected_actions_dots {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(118, 116, 147, 0.11);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 30px;
    transition: 0.3s;
}

.selected_actions_dots.selected {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(118, 116, 147, 0.11);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 30px;
    transform: rotateZ(-90deg);
    transition: 0.3s;
}

.selected_action_dot {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #A2ADBF;
}
.selected_actions_menu_hide {
    display: none;
}
.selected_actions_menu {
    width: 207px;
    background-color: white;
    position: absolute;
    right: 65px;
    bottom: -126px;
    z-index: 2;
}

@media (max-width: 600px) {
    .selected_actions_menu {
        width: 207px;
        background-color: white;
        position: absolute;
        left: 0px;
        bottom: -126px;
        z-index: 2;
    }

}

.selected_actions_menu > div {
    display: flex;
    align-items: center;
    height: 55px;
    padding-left: 20px;
    cursor: pointer;
}
.selected_actions_menu > div:not(:last-child) {
    border-bottom: 1px solid rgba(112, 112, 112, 0.11);
}

.selected_actions_menu > div > img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.selected_actions_menu > div > p {
    color: #4B5960;
    font-size: 0.8rem;
    font-weight: 600;
}
