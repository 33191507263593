.secondary-container {
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
    background-color: white;
}

.download-wrapper {
    display: grid;
    grid-template-columns: 40% 60%;
}
@media (max-width: 700px) {
    .download-wrapper {
        grid-template-columns: 1fr;
    }
}

.download-app-image {
    width: 100%;
    height: 100%;
    text-align: right;
}
@media (max-width: 700px) {
    .download-app-image {
        display: none;
    }
}

.download-app-image > img {
    max-width: 90%;
    margin-right: 45px;
}

@media (max-width: 950px) {
    .download-app-image {
        height: 65%;
        padding-top: 10%;
        width: 100%;
    }
}

@media (max-width: 500px) {
    .download-app-image {
        height: 50%;
    }
}

@media (max-width: 700px) {
    .download_btn {
        width: 70%;
    }
    h2{
        float: revent;
        font-size: 12px;
    }
}

