.dashboard_form_success_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.dashboard_form_success_container > h4 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dashboard_form_success_img {
  width: 100px;
}

.dashboard_form_success_container > p {
  width: 300px;
  text-align: center;
  margin-top: 20px;
  line-height: 30px;
  color: #4A4A4A;
}
