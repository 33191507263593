.tabs .tab-header {
    height:60px;
    display:flex;
    align-items:center;
}
.tabs .tab-header > div {
    width: 150px;
    text-align:center;
    color:#888;
    font-weight:600;
    cursor:pointer;
    font-size:14px;
    text-transform:uppercase;
    outline:none;
}
.tabs .tab-header > div > i {
    display:block;
    margin-bottom:5px;
}
.tabs .tab-header > div.active {
    color:#00acee;
}
.tabs .tab-indicator {
    position:relative;
    width: 150px;
    height:5px;
    background:#00acee;
    left:0px;
    border-radius:5px;
    transition:all 500ms ease-in-out;
}

.modal_search_box {
    max-width: 200px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
