.custom-select-wrapper {
    position: relative;
    user-select: none;
    min-width: 228px;
    margin-left: 15px;
}
@media (max-width: 580px) {
    .responsive_select {
        min-width: 128px;
    }
}
.custom-select {
    position: relative;
    display: flex;
    flex-direction: column;
}
.custom-select__trigger {
    position: relative;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    background-color: #FD552B;
    color: #ffffff;
    cursor: pointer;
    border-radius: 7px;
}
.custom-options {
    padding-top: 10px;
    position: absolute;
    display: block;
    top: 100%;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    left: 0;
    right: 0;
    border-top: 0;
    background: #fff;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2000;
}
.custom-select.open .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}

@media (max-width: 600px) {
    .custom-options {
        z-index: 700;
        visibility: visible;
    }
}

.permission_custom_menu {
    width: 620px;
    background-color: white;
    position: absolute;
    border: 1px solid #D7D7DE;
    padding: 10px;
    right: -275%;
}
@media (max-width: 1250px) {
    .permission_custom_menu {
        right: 0px;
        top: 250px;
    }
}
@media (max-width: 580px) {
    .permission_custom_menu {
        top: 320px;
        right: 0%;
        width: 180%;


    }
}
.permission_custom_menu_left {
    width: 620px;
    background-color: white;
    position: absolute;
    border: 1px solid #D7D7DE;
    padding: 10px;
    right: -275%;
}
.perm_menu_link {
    color: #0E0A40;
    text-decoration: underline;
    cursor: pointer;
}
.perm_menu_box {
    display: grid;
    grid-template-columns: 70% 30%;
    margin-bottom: 1rem;
}
.perm_menu_box > div > h4 {
    font-size: 14px;
    color: black;
}
.perm_menu_box > div > p {
    font-size: 12px;
    color: #b0abab;
    line-height: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
}
.perm_radio {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(max-width:600px){
    .perm_radio{
        margin-right: -6px;
    }
}

.custom-option {
    position: relative;
    display: block;
    padding: 0 12px 0 12px;
    font-size: 14px;
    font-weight: 300;
    color: #3b3b3b;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.4s;
}
.custom-option:hover {
    cursor: pointer;
    background-color: #e0d7d7;
}
.custom-option.selected {
    color: #ffffff;
    background-color: #305c91;
}
.select-arrow {
    width: 15px;
    transition: 0.4s;
}
.select-arrow-up {
    width: 15px;
    transition: 0.4s;
    transform: rotateX(-180deg);
}

.permission_select_trigger {
    background-color: white;
    border: 1px solid rgba(14, 10, 64, 0.5);
    position: relative;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    color: black;
    cursor: pointer;
    border-radius: 3px;
}

@media(max-width:600px){
    .permission_select_trigger{
        right: 30px;
    }
}


.permission_select_trigger > span {
    font-size: 14px;
    font-weight: 500;
}

.permission_btn_box {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: end;
    padding: 5px;
}
@media (max-width: 580px) {
    .permission_btn_box {
        display: flex;
        flex-direction: column-reverse;
    }
    .text_link {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
.text_link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
}

@media (max-width: 500px) {
    .custom-select-wrapper {
        min-width: 150px;
        margin-left: 5px;
        width: 100%;
        padding: 0 5px;
    }
    .custom-select__trigger {
        font-size: 14px;
        height: 50px;
        padding: 0px 7px;
    }
    .select-arrow {
        width: 10px;
    }
    .select-arrow-up {
        width: 10px;
    }
    .custom-option {
        font-size: 14px;
    }
}
