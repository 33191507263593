.radio_text {
    font-size: 12px;
}

.input {
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    height: 30px;
    background-color: #F0F0F0;
    padding-left: 10px;
}

.input.div {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 10px;
}

.input.div.selected {
    border: 1px solid black;
}


.input.error {
    border: 1px solid darkred;
}

.input_error {
    position: absolute;
    bottom: -25px;
    left: 0;
    font-size: 10px;
    color: #ED4337;
}

.input_error.main {
    position: absolute;
    bottom: -5px;
    left: 83%;
    font-size: 10px;
    color: #ED4337;
}

.radio_group {
    display: flex;
    flex-direction: column;
    max-width: 740px;
}

.radio_container:not(:first-child) {
    margin-top: 15px;
}

.radio_description {
    padding-left: 35px;
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 0;
    color: #727070;
}

.feature_description {
    margin-top: 5px;
    font-size: 12px;
    color: #727070;
}
