.create_card_success_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create_card_success_container > h4 {
    font-size: 37px;
    font-weight: bold;
    color: #191818;
    margin-bottom: 40px;
}

.create_card_success_container > img {
    width: 80%;
    max-width: 120px;
    margin-bottom: 40px;
}

.create_card_success_container > p {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}
