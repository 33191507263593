.plan_billing_info {
    font-size: 12px;
}

.plan_billing_info > p {
    margin-bottom: 3px;
}

.plan_calendar_day_badge {
    display: flex;
    align-items: center;
}

.plan_calendar_day_badge > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.plan_calendar_day_badge.sick > div {
    background-color: #c64422;
}
.plan_calendar_day_badge.holiday > div {
    background-color: #0E0A40;
}
.plan_calendar_day_badge.rest > div {
    background-color: #00acee;
}

.plan_calendar_day_badge > p {
    margin: 0;
    font-size: 12px;
    padding-left: 7px;
}

.plan_billing_heading {
    display: flex;
    flex-direction: column;
}

.plan_billing_heading > h6 {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 5px;
}

.plan_billing_heading > p {
    font-size: 16px;
    color: #130F30;
    margin-bottom: 5px;
}

.plan_billing_heading_right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
