.modal_container {
    min-width: 572px;
    background-color: white;
    position: relative;
    z-index: 2;
    padding: 0 20px 20px 20px;
}

.update_modal_row {
    display: flex;
    gap: 20px;
}
@media (max-width: 600px) {
    .modal_container {
        min-width: auto;
        width: 80vw;
        padding: 0 14px 14px 14px;
    }
    .active_icon_plans_submit {
        display: none;
    }
}

.modal_container_big {
    min-width: 90vw;
    max-width: 95vw;
    max-height: 95vh;
    position: relative;
    z-index: 9999;
    padding: 20px;
}

.modal_bg {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    background-color: #F2F2F4;
    padding: 20px;
}

.white {
    background-color: #ffffff;
}
@media (max-width: 1000px) {
    .plans_modal_filter {
        display: block;
    }
    .plans_modal_filter > div {
        margin-bottom: 10px;
    }
}
.modal_container > h2 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}
.close_popup {
    position: absolute;
    width: 18px;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.close_popup_paddings {
    position: absolute;
    width: 15px;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.success_pop_img {
    margin-top: 15px;
    margin-bottom: 50px;
    width: 80px;
}
.modal_line {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    background-color: #9FACC7;
}
.modal_container > p {
    font-size: 16px;
    font-weight: 600;
    padding-top: 15px;
    margin-bottom: 20px;
}
.modal_btn_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.modal_cancel_btn {
    margin-right: 20px;
    font-weight: 700;
    cursor: pointer;
}
/* PLANS AND MODULES */
.plans_pop_text {
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    letter-spacing: 0.05rem;
    color: #4A4A4A;
}
.filter_header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.modal_close_text {
    width: 100%;
    margin-top: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}

/* COMPLETE PROFILE */
.complete_title {
    font-size: 1.5rem!important;
    padding-top: 1rem;
    margin-bottom: 3rem;
    text-align: center;
}
.complete_row_selected {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid rgba(30, 192, 166, 0.55);
    background-color: rgba(30, 192, 166, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
}
.complete_text {
    font-size: 1rem;
    font-weight: 600;
    padding-left: 0.6rem;
}
.complete_text_completed {
    font-weight: 600;
    color: #1EC0A6;
}
.complete_text_complete_now {
    color: #515DA7;
    font-weight: 600;
    cursor: pointer;
}
.complete_row_selected:last-child {
    margin-bottom: 5rem;
}

.csv_pop_wrapper {
    padding: 2rem;
    width: 100%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.perm_table_select {
    max-width: 120px;
}

.search_box_permissions {
    max-width: 180px;
    margin-top: 20px;
    margin-bottom: 1rem;
}

.employee_desktop_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employee_mobile_button {
    display: none;
}

.validate_warning_csv {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    column-gap: 10px;
}

.validate_warning_csv > p {
    font-size: 12px;
    color: #DC3545;
    cursor: pointer;
    font-weight: 600;
}

@media (max-width: 600px) {
    .employee_desktop_button {
        display: none;
    }
    .employee_mobile_button {
        display: block;
    }
}


.changelog_modal {
    max-width: 80vw;
    max-height: 80vh;
    position: relative;
    z-index: 1000;
}


.changelog_wrapper > ul, .changelog_wrapper > ul > li{
    list-style: none;
    padding: 0;
}

.changelog_wrapper{
    padding: 2rem;
    overflow: auto;
}
.sessions{
    margin-top: 2rem;
    border-radius: 12px;
    position: relative;
}
.changelog_wrapper > ul > li {
    padding-bottom: 1.5rem;
    border-left: 1px solid #A2ADBF;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
}
.changelog_wrapper > ul > li:last-child{
    border: 0px;
    padding-bottom: 0;
}
.changelog_wrapper > ul > li:before{
    content: '';
    width: 25px;
    height: 25px;
    background-color: white;
    background-size: cover;
    background-image: url("update_calendar_icon.svg");
    position: absolute;
    left: -12px;
    top: 0px;
}

.time {
    padding-top: 3px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #A2ADBF;
}

.changelog_name {
    font-size: 12px;
    font-weight: 600;
    color: rgba(19,15,48,0.65);
}

.changelog_badge {
    width: max-content;
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 11px;
    color: #f2a338;
    background-color: rgba(255, 236, 206, 0.8);
}
