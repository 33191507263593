.employee_page {
	padding: 30px;
	min-height: 100%
}

.secondary {
	transition: .25s;
	color: #fc542b
}

.secondary:hover {
	color: #c64422
}