.dashboard_page {
	padding: 30px
}

.chartjs-render-monitor {
	/* max-width: 300px;
	max-height: 250px;
	min-width: 300px;
    min-height: 250px; */
}

.dashboard_hrzone-container {
	background: #fff;
	width: 100%;
	min-height: 250px;


	display: grid;
	grid-template-columns: 1fr 6fr 1fr;
	position: relative;

}

.dashboard_card {
	border-radius: 15px;
	background: #fff;
	padding: 10px 20px;
	height: fit-content;
	min-height: 97px;
	min-width: 220px;

	position: relative;
	width: 100%;

	margin: auto;

	display: flex;
	justify-content: space-between;
	align-items: center;
}


.dashboard_employee-border {
	border-right: 1px solid #A8A8A8;
}

.dashboard_employee-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 30px;
}

.dashboard_dot-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.dashboard_employee-dot {
	width: 15px;
	height: 15px;
	border-radius: 100%;
	background: #f8542b;
	margin-Right: 15px;
}



.dashboard_category-container {
	display: grid;
	grid-template-columns: 1fr 3fr;
	padding: 30px
}

.dashboard_cards-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 20px;
	row-gap: 20px;
	height: fit-content;
	margin: auto;
	width: 95%
}

.dashboard_card-image {
	width: 60px;
	height: 60px;

}

.dashboard_card-textContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}


.card_text {
	margin-bottom: 8px;
	font-size: 12px
}

.card_text-percentage {
	font-size: 22px;
	font-weight: 700
}

.dashboard_category-circle {
	width: 300px;
	height: 300px;
	margin: auto;
	display: flex;
	align-items: center;
}

.dasboard_employee-circle {
	width: 260px;
	height: 260px
}

.dashboard_employee-button {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: column;
}


@media (max-width: 1410px) {
	.dashboard_cards-container {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media (max-width: 1300px) {
	.dashboard_cards-container {
		grid-template-columns: 1fr 1fr;
	}

	.dashboard_hrzone-container {
		grid-template-columns: 1fr;
	}

	.dasboard_employee-circle {
		margin: auto;
		margin-bottom: 30px
	}

	.dashboard_employee-button {
		margin: auto;
		display: block;
		margin-top: 30px;
		min-width: 200px
	}
}

@media (max-width: 900px) {
	.dashboard_cards-container {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.dashboard_category-container {
		grid-template-columns: 1fr;
	}

	.dashboard_category-circle {
		margin: 0 auto 30px auto;
	}

	.dashboard_employee-border {
		border-right: none;

	}
}

@media (max-width: 768px) {
	.dashboard_cards-container {
		grid-template-columns: 1fr 1fr;
	}

	.dashboard_employee-container {
		grid-template-columns: 1fr;
		margin-top: 30px;
	}

	.dashboard_employee-border {

		margin-bottom: 30px;
	}
}


@media (max-width: 550px) {
	.dashboard_cards-container {
		grid-template-columns: 1fr;
		width: 100%
	}

}