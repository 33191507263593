.main-container {
    width: 100%;
    overflow-y: scroll;
    height: calc(100vh - 100px);
    position: relative;
}

.main_container_full {
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
    position: relative;
}
