.payment_overview_header_component_account_info_container {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 58% 1fr;
    grid-gap: 16px;
}

.payment_overview_header_component_account_info_card {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    -moz-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
}

.payment_overview_header_component_account_info_card_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.payment_overview_header_component_account_info_card_grid > div {
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.payment_overview_header_component_account_info_card_grid > div > div {
    min-height: 60px;
    height: 90%;
    width: 100%;
    border-right: 1px solid #cecece;
}

.payment_overview_header_component_account_info_card_grid > div > div > h6 {
    font-size: 14px;
    font-weight: normal;
    color: rgba(0,0,0, 0.36);
    margin-bottom: 10px;
}

.payment_overview_header_component_account_info_card_grid > div > div > p {
    font-size: 18px;
    font-weight: normal;
    color: #000000;
    margin-bottom: 10px;
}
