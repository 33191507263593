.banner_title {
    padding-left: 40px;
    padding-top: 23px;
}

.banner_title > p {
    font-size: 17px;
    font-weight: 300;
    padding-top: 10px;
    color: #0E0A40;
}

.banner_title > h2 {
    font-size: 24px;
    font-weight: 700;
    padding-top: 10px;
    color: #0E0A40;
}

@media (max-width: 600px) {
    .banner_title > h2 {
        font-size: 22px;
        display: flex;
        justify-content: center;
    }
    .banner_title {
        padding-left: 16px;
    }
}
