.side_products {
    width: 100%;
    height: 100%;
    padding: 0 25px 0 23px;
}
.side_prod_img {
    width: 100%;
}
@media (max-width: 1100px) {
    .side_prod_img > img {
        margin-top: 0;
        max-height: 150px;
        width: auto;
    }
}
.side_prod_img > img {
    margin-top: 87px;
    width: 90%;
}

div.space_between_products p {
    font-size: 12px;
    font-weight: 600;
}

.products_row {
    width: 100%;
    margin-top: 20px;
}
.side_product_container {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}
.space_between_products {
    width: 100%;
}
.products_progress {
    margin-top: 12px;
    width: 100%;
    height: 6px;
    background-color: #d1f0f1;
    border-radius: 5px;
    position: relative;
}
.products_progress > div {
    height: 6px;
    border-radius: 5px;
}
.progress_one {
    background-color: #1EC0A6;
}
.progress_two {
    background-color: #FFAE4E;
}
.product_hint_text {
    font-size: 13px;
    font-weight: 300;
    text-align: center;
}

.product_side_image {
    width: 20px;
}

@media (max-width: 1470px) {
    .side_product_container {
        padding-right: 10px;
        padding-left: 10px;
    }
}
@media (max-width: 1300px) {
    .side_product_container {
        font-size: 14px;
    }
    .space_between_products > p {
        padding-right: 5px;
    }
}
@media (max-width: 1050px) {
    .side_prod_img {
        display: none;
    }
    .products_row {
        max-width: 600px;
    }
}


