h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 12px;
}

@media (max-width: 600px) {
    h3 {
        font-size: 14px;
        line-height: 20px;
    }
}
