.billing_column {
    display: flex;
    align-items: center;
    position: relative;
}

.billing_column > img {
    width: 15px;
}

.billing_column > input {
    max-width: 30px;
    border: none;
}

.billing_column_warning {
    position: absolute;
    left: -30px;
}

.billing_column_warning > img {
    width: 16px;
}

.billing_column_warning > div {
    background-color: #59567E;
    color: #ffffff;
    position: absolute;
    z-index: 2;
    left: 4px;
    bottom: 20px;
    width: 299px;
    padding: 10px;
    border-radius: 4px;
}

.billing_column_warning > div:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -10px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 16px solid #59567E;
}
