.lunch_datepicker {
    margin-left: 10px;
    width: 176px;
    height: 50px;
    border: 1px solid #CDE1EB;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 10px;
}
@media (max-width: 600px) {
    .lunch_datepicker {
        width: 150px;
    }
}
.date_add_btn {
    border: none;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;
    width: 175px;
    height: 40px;
    position: relative;
    cursor: pointer;
}

.add_employee {
    margin-top: 6px;
    border: none;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    width: 300px;
    height: 40px;
    position: relative;
    cursor: pointer;
}

@media (max-width: 1310px) {
    .add_employee {
        padding: 0 10px;
    }
}

.add_employee > img {
    position: absolute;
    width: 18px;
    left: 10px;
    top: 30%;
}

@media (max-width: 1560px) {
    .add_employee > img {
        display: none;
    }
}

.custom_calendar > .rdrCalendarWrapper {
    margin-top: 2rem;
    background-color: #ffffff!important;
    border-radius: 10px;
    box-shadow: 0px 1px 30px rgba(53,70,171,0.14);
}
.rdrDay {
    background: #ffffff!important;
}

