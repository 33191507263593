.budget_moving_modal_container {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.budget_moving_modal_container > h3 {
    font-size: 20px;
    font-weight: 600;
}

.budget_moving_modal_container > p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
}

.budget_moving_modal_container > div {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 30px;
}
