.branches_chart_header {
    max-width: 500px;
    width: 100%;
    margin-bottom: 20px;
}
.branches_chart_row > p {
    font-size: 14px;
    color: #767493;
    font-weight: 300;
    padding-left: 10px;
}

.branches_text {
    font-size: 12px;
    font-weight: 500;
    color: #7C828A;
}

.branches_chart_container {
    position: relative;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 265px;
}
.chart_box {
    width: 96%;
    max-width: 100%;
    box-sizing: border-box;
    height: 240px;
    min-width: 600px;
}

.charts_labels {
    font-size: 11px;
    font-weight: 500;
    color: #767493;
    letter-spacing: 0.5px;
}

@media (max-width: 700px) {
    .branches_chart_container {
        overflow-x: scroll;
    }
}

@media (max-width: 500px) {
    .branch_chart_row {
        display: block;
    }
}
