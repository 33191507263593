.transfer_header {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(162, 173, 191, 0.22);
    height: 76px;
    background-color: #EAEAF0;
    border-radius: 5px;
    font-size: 28px;
    font-weight: bold;
    color: #626262;
}
