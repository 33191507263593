.payments_login_page {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.payments_login_form {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(178, 178, 178, 0.16);
}


.main-container::-webkit-scrollbar {
    display: none;
}

.create_password_page {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 220px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.create_password_page_container {
    min-width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

.create_password_page_container > h3 {
    text-align: center;
}

.code_input {
    width: 240px;
    border: 2px solid rgba(0, 190, 170, 1);
    height: 40px;
    outline: none;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 20px;
    padding-left: 10px;
    letter-spacing: 12px;
}

.verification_form {
    background-color: #FFFFFF;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 40px;
}

.verification_form_content {
    width: 100%;
}

.verification_form_content > h1 {
    font-size: 18px;
    margin-bottom: 10px;
}

.verification_form_content > p {
    font-size: 14px;
    margin-bottom: 20px;
}

.verification_form_content_input > p {
    font-size: 12px;
    margin-bottom: 5px;
}

.verification_form_content_input > input {
    margin-top: 0;
    max-width: 250px;
}

.verification_form > div > img {
    max-width: 70%;
}

@media (max-width: 900px) {
    .verification_form {
        grid-template-columns: 1fr;
    }
    .verification_form > div:nth-child(2) {
        display: none;
    }
}

.weavr_passcode_container {
    padding: 40px;
    background-color: #FFFFFF;
    border-radius: 5px;
    width: 88%;
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.weavr_passcode_container > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.weavr_passcode_content {
    width: 100%;
}

.weavr_passcode_container > div > img {
    max-width: 60%;
}

#passcode {
    height: 40px;
    border: 1px solid rgba(14, 10, 64, 0.15);
    max-width: 250px;
    border-radius: 5px;
    margin-top: 3px;
    padding-top: 10px;
    padding-left: 5px;
}

.weavr_passcode_content > h1 {
    font-size: 18px;
    margin-bottom: 30px;
}

.weavr_passcode_input_box {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    min-width: 220px;
}

.weavr_passcode_btn {
    width: 100%;
    border-radius: 7px;
    height: 40px;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    max-width: 250px;
    cursor: pointer;
    letter-spacing: 0.1px;
    background-color: #fd552b!important;
    color: #ffffff!important;
    font-size: 0.9rem;
}

.card_grid_2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.card_grid_3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.card_form {
    width: 100%;
    max-width: 800px;
    position: relative!important;
}

.detail_view {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
}

.card_detail_main {
    border-right: 1px solid #cecece;
    padding-right: 40px;
}

.card_detail_detail {
    padding: 0 20px;
}

.card_detail_buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.card_chart_card {
    background-color: #FFFFFF;
    margin-top: 20px;
    padding: 15px;
    border-radius: 10px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    max-height: 420px;
}

.card_onboarding_info_page_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #FFFFFF;
    padding: 50px;
    margin-top: 60px;
    max-width: 88%;
    margin-left: auto;
    margin-right: auto;
}

.card_onboarding_info_page_card > h1 {
    font-size: 18px;
    margin-bottom: 20px;
}

.card_onboarding_info_page_card > p {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 40px;
}

.card_onboarding_info_page_container > div:nth-child(2) > img {
    max-width: 70%;
}

@media (max-width: 900px) {
    .card_onboarding_info_page {
        grid-template-columns: 1fr;
    }

    .card_onboarding_info_page > div:nth-child(2) {
        display: none;
    }
}

.weavr_login_container_content {
    width: 100%;
}

.weavr_login_container_content > h1 {
    font-size: 18px;
    margin-bottom: 20px;
}

.weavr_login_container_content > p {
    font-size: 14px;
    margin-bottom: 20px;
}

.kyb_box {
    background-color: #FFFFFF;
    width: 100%;
    padding: 40px;
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 88%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}

.kyb_box > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.kyb_box_content > h1 {
    font-size: 18px;
    margin-bottom: 20px;
}

.kyb_box_content > p {
    font-size: 16px;
    margin-bottom: 20px;
}

.kyb_box > div > img {
    max-width: 70%;
}

.payments_card_detail_header > h1 {
    color: #3D3D8E;
    font-size: 24px;
    font-weight: bold;
}

.payments_card_detail_header > h2 {
    color: #0D0A3D;
    font-size: 18px;
    font-weight: normal;
}

.payments_card_detail_top_section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
}
