/* SUMMARY */

.summary_row {
    margin-top: 2px;
    margin-bottom: 6px;
}
.summary_row > p {
    font-size: 11px;
    font-weight: 500;
    color: #767493;
    letter-spacing: 0.5px;
}
.summary_dot {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}
.red_summary {
    background-color: #FF5242;
}
.blue_summary {
    background-color: #6457E8;
}
.summary_stat {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 20px;
    margin-bottom: 6px;
}
.summary_stat > p {
    font-size: 11px;
    font-weight: 500;
    color: #767493;
    letter-spacing: 0.5px;
    margin-right: 7px;
    text-align: right;
    vertical-align: baseline;
}
.summary_stat > span {
    font-size: 12px;
    padding-left: 7px;
    font-weight: 600;
}

.summary_stat > img {
    margin-left: 10px;
    padding-bottom: 2px;
}

@media (max-width: 1300px) {
    .summary_opt_container {
        height: 30px;
        font-size: 12px;
        min-width: 120px;
    }
    .summary_opt {
        padding: 3px;
    }
    .summary_opt_selected {
        padding: 5px;
    }
}
.summary_chart_container {
    max-width: 100%;
    width: 100%;
    padding-top: 20px;
    height: 320px;
}

@media (max-width: 400px) {
    .summary_opt_container {
        position: absolute;
        right: 10px;
        top: 46px;
    }
}
@media (max-width: 350px) {
    .summary_opt_container {
        right: 10px;
        top: 60px;
    }
}
