.dashboard_footer {
    bottom: 0;
    padding: 10px 20px;
    background-color: #0E0A40;
    width: 100%;
}

.dashboard_footer_top_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.dashboard_footer_bottom_text {
    font-size: 12px;
    margin-bottom: 20px;
    color: #6b6b6b;
}

.dashboard_footer_top_box {
    display: flex;
    flex-direction: column;
}

.dashboard_footer_top_container > div > h3 {
    color: #FFFFFF;
    margin-bottom: 5px;
    line-height: 20px;
}

.dashboard_footer_top_box > a {
    margin-top: 0;
    margin-bottom: 3px;
    font-size: 11px;
    color: #FFFFFF;
    text-decoration: none;
}

.footer_download_btn {
    height: 34px;
    margin-right: 20px;
}

.dashboard_footer_bottom_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0px;
    color: #6b6b6b;
    font-size: 11px;
}

.dashboard_footer_bottom_box > .row > a {
    color: #6b6b6b;
    text-decoration: none;
    transition: 0.3s;
}

.dashboard_footer_bottom_box > .row > a:hover {
    color: #ffffff;
}

.dashboard_footer_bottom_box > .row > a:not(:last-child) {
    padding-right: 5px;
    border-right: 1px solid #6b6b6b;
    margin-right: 5px;
}
