.dashboard_container {
    width: 100%;
    min-height: 100%;
    display: grid;
    grid-template-columns: 16% 84%;
    background-color: #F2F2F4;
    position: relative;
    z-index: 99!important;
}
.dashboard_hide_sidebar {
    width: 100%;
    min-height: 100%;
    display: grid;
    grid-template-columns: 90px 1fr;
    background-color: #F2F2F4;
}

@media (max-width: 1000px) {
    .dashboard_hide_sidebar {
        display: block;
    }
}

.dashboard_banner_img {
    width: 100%;
    height: 100%;
}
.main_sections {
    max-width: 100vw;
    width: 100%;
    display: grid;
    grid-template-columns: 75% 25%;
}

.dashboard_settings_container {
    height: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}
.export_button {
    width: 140px;
    height: 40px;
    background-color: #EDEEF4;
    border-radius: 7px;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 15px;
    font-size: 16px;
}
.dashboard_main_banner {
    position: relative;
    background-image: url('../../img/header.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 175px;
}
@media (max-width: 1000px) {
    .dashboard_main_banner {
        background-position: left;
    }
}
.summary_chart_grid {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 50% 50%;
}
@media (max-width: 900px) {
    .summary_chart_grid {
        display: block;
    }
}
.right_section {
    padding: 0 20px 20px 0;
}
@media (max-width: 1000px) {
    .responsive_side {
        min-width: 95vw;
        max-width: 95vw;
        margin-left: auto;
        margin-right: auto;
    }
    .right_section {
        margin-left: auto;
        margin-right: auto;
        max-width: 96vw;
        width: 100%;
        padding: 0;
        margin-bottom: 40px;
    }
}

.second_side {
    height: 605px;
}

.right_section_content {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}
.top_side {
    width: 100%;
    border-radius: 15px;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    height: 918px;
}
.top_bottom {
    position: relative;
    height: 770px;
    background-image: url("../../img/dash_benefits_banner.png");
    background-position: center;
    background-size: 140% 140%;
}
.btn_ben {
    position: absolute;
    width: 150px;
    left: 15px;
    bottom: 25px;
}

.benefits_banner_text {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    color: white;
    font-weight: 300;
    text-align: center;
}
.benefits_banner_img {
    width: 100%;
}
/* NEED ANOTHER IMAGE WITHOUT PADDINGS */
.benefits_banner_img > img {
    margin-top: -90px;
    max-width: 100%;
}
.left_section_first {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    margin-top: 30px;
}
.left_section_charts {
    width: 100%;
    min-height: 320px;
    padding: 20px;
}

.left_section_first_top {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.download_btn {
    width: 70%;
    min-width: 120px;
    margin-right: 1rem;
    cursor: pointer;
}
@media (max-width: 700px) {
    .left_section_first_top {
        display: block;
    }
    .left_section_charts {
        padding: 10px;
    }
}

.profile_completion_invoices_container {
    padding-left: 35px;
    padding-top: 20px;
}

.profile_completion_invoices_container > h2 {
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;
}

.profile_completion_invoices_container > p {
    font-size: 14px;
    font-weight: 300;
    color: #FFFFFF;
    max-width: 300px;
}

.dash_first_one {
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    background-color: white;
}
.left_section_first_bottom {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    height: 70%;
    box-sizing: border-box;
}

.month_box {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    background: rgb(14,0,247);
    background: linear-gradient(0deg, rgba(14,0,247,0.0844712885154062) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(bottom,rgba(14,0,247,0.1),rgba(255,255,255,0.3) 45%,rgba(255,255,255,0));
}
.month_text {
    font-size: 20px;
    padding-left: 15px;
    font-weight: 600;
}
.crown_prod {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
.crown_prod > img {
    width: 80px;
}
.prod_main_text {
    padding-top: 10px;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
}
.dash_first_three {
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
}
.left_section_second_top {
    width: 100%;
    height: 50%;
}
.dash_second_container {
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    position: relative;
}
.dash_table_container {
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: white;
    max-width: 87vw;
}

@media (max-width: 800px) {
    .dash_table_container {
        max-width: 96vw;
    }
}

.dash_benefits_container {
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
    width: 100%;
    height: 650px;
    position: relative;
    background-color: white;
}
/* USING ONLY FOR CHARTS IN DASHBOARD */
.grid {
    display: grid;
    grid-template-columns: 50% 50%;
}
.vl {
    width: 1px;
    height: 100%;
    background-color: #CDE1EB;
    left: 50%;
    position: absolute;
}
.dash_second_one {
    width: 100%;
    height: 100%;
}
.left_section_second_bottom {
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
}
@media (max-width: 700px) {
    .left_section_second_bottom {
        padding: 0 20px 20px 0;
    }
}
@media (max-width: 600px) {
    .left_section_second_bottom {
        padding: 0;
    }
}
.dash_third {
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
    width: 100%;
    max-width: 82vw;
    height: 100%;
}
.main_large_block {
    width: 100%;
    height: 600px;
    margin-bottom: 20px;
    padding: 20px;
}
.main_large_block_content {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
    padding: 20px;
}

.main_large_block_content_row {
    width: 100%;
    height: 100%;
    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5%;
}
.dash-bottom {
    height: 300px;
    background: rgb(14,0,247);
    background: linear-gradient(0deg, rgba(14,0,247,0.0844712885154062) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(bottom,rgba(14,0,247,0.1),rgba(255,255,255,0.3) 55%,rgba(255,255,255,0));
    display: grid;
    grid-template-columns: 40% 60%;
}
.dash-bottom > img {
    margin-left: 2%;
    margin-top: 5%;
    max-height: 260px;
}

.welcome_button {
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 160px;
}

/* NEW EMPLOYEE */
.dash_last_employee_container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
}
.employee_image {
    width: 45px;
    height: 45px;
    margin-right: 20px;
    border-radius: 50%;
}
.employee_name {
    font-size: 16px;
    font-weight: 600;
    color: #7a7a7a;
}
.employee_role {
    font-size: 11px;
    font-weight: 500;
    color: #767493;
    letter-spacing: 0.5px;
}

/* DOWNLOAD SECTION */

.download_title {
    color: #242424;
    font-size: 28px;
    margin-bottom:15px;
}

.download_image {
    max-height: 240px;
}

/* LIST MERCHANT */
.dash_list_container {
    padding: 20px;
}

/* HELP SECTION */
.dash_help_title {
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 15px;
}
.dash_help_text {
    font-size: 10px;
    color: #9B9B9B;
}


/* CONTAINERS TITLES */

.dash_container_top {
    width: 90%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
}

.dash_container_more,
.dash_container_more a {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #7C828A;
    padding-right: 10px;
    text-decoration: none;
}
@media (max-width: 600px) {
    .dash_container_more,
    .dash_container_more a {
        font-size: 12px;
    }
}
.dash_container_more:hover,
.dash_container_more a:hover {
    color: #999999;
}


@media (max-width: 1400px) {
    .prod_main_text {
        font-size: 22px;
    }
}

@media (max-width: 1330px) {
    .welcome_right {
        grid-template-rows: 38% 62%;
    }
}

@media (max-width: 1300px) {
    .dashboard_container {
        grid-template-columns: 1fr;
    }
    .settings_image_box {
        display: flex;
        justify-content: flex-end;
    }
}

@media (max-width: 1200px) {
    .main_sections {
        grid-template-columns: 1fr;
    }
    .left_section_first {
        max-width: 96vw;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
    .welcome_image {
        display: none;
    }
}
