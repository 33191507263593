.infoCard {
    background-color: #ffffff!important;
    padding: 16px;
    border-radius: 8px;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    -moz-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    min-height: 120px;
    display: flex;
    align-items: flex-start;
    position: relative;
}

.infoCard_link {
    position: absolute;
    right: 16px;
    bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.infoCard_link > p {
    font-size: 12px;
    font-weight: 500;
    color: rgba(86, 82, 94, 1);
}

.infoCard_link > img {
    margin-left: 7px;
    height: 10px;
}

.infoCard_image_container {
    width: 60px;
    height: 60px;
    background-color: rgba(242, 242, 244, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    margin-right: 15px;
}

.infoCard_content {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.infoCard_content > h6 {
    font-size: 16px;
    font-weight: 700;
    color: rgba(86, 82, 94, 1);
}

.infoCard_content > h5 {
    font-size: 22px;
    font-weight: 700;
    color: rgba(86, 82, 94, 1);
}
