.profile_page {
	padding: 30px
}

.profile_container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 30px;
	row-gap: 30px
}

.profile_card {
	border-radius: 20px;
	background: #fff;
	padding: 30px;
	min-height: 250px;
	min-width: 400px;
	position: relative;


	display: flex;
	flex-direction: column;
	justify-content: space-between
}

.full {
	width: 100%;
	margin-top: 30px;
	margin-bottom: 30px;
}

.profile_card-image {
	position: absolute;
	top: 0;
	right: 0;
	max-Width: 150px
}

.profile_employee-container {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
}

.profile_employee-container p {
	width: fit-content;
	color: rgb(168, 168, 168);
	/* padding: 0 0 12px 0; */
padding: 1em;
	font-weight: 600;
}


.profile_company-address {
	color: rgb(168, 168, 168);
	font-weight: 600;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	font-size: 1em;
}

.profile_company-address p {
	color: #000 !important;
	font-size: 1.1em;
}

.profile_location-text {
	width: 30%;
	color: rgb(168, 168, 168);
	margin-Top: 20px;
	min-width: 200px;
}

.profile_location-missingCompanies {
	font-size: 0.9rem !important
}

@media (max-width: 1400px) {
	h1 {
		font-size: 1.5em
	}

	.profile_card h1:nth-child(2) {
		font-size: 48px;
	}

	.profile_location-text {
		width: 45%
	}
}

@media (max-width: 900px) {
	.profile_container {
		grid-template-columns: 1fr;
	}

	.profile_location-text {
		width: 60%
	}

	.profile_employee-container p {
		/* width: 33%; */
width: fit-content;
padding: .5em
	}
}

@media (max-width: 600px) {
	.profile_employee-container p {
		/* width: 50%; */
	}
}