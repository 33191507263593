.invoice_overview_top_cards_title {
    font-size: 20px;
    font-weight: 400;
    color: #0D0A3D;
    margin-bottom: 14px;
}

.invoice_overview_top_cards_list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.invoice_overview_top_cards_container {
    width: 100%;
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.082);
    border-radius: 7px;
    padding: 29px 38px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.invoice_overview_top_cards_card_title {
    position: relative;
}

.invoice_overview_top_cards_card_title > div {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: rgba(139, 188, 188, 1);
    position: absolute;
    left: -20px;
    top: 2px;
}

.invoice_overview_top_cards_card_title > p {
    color: rgba(118, 116, 147, 1);
    font-size: 13px;
    font-weight: 700;
}

.invoice_overview_top_cards_container > div > h5 {
    font-size: 30px;
    font-weight: 700;
    margin-top: 7px;
    margin-bottom: 0;
}

.invoice_overview_top_cards_card_image {
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(118, 116, 147, 0.11);
    border-radius: 20px;
}

.invoice_overview_top_cards_card_image > img {
    height: 28px;
}
