footer {
    width: 100%;
    box-sizing: border-box;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0e0a40;
    padding-left: 20px;
    padding-right: 20px;
}

footer > h4 {
    color: #0e0a40;
    font-weight: 300;
    font-size: 16px;
}
