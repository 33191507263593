.max_limit {
    width: 100%;
    height: 100%;
    padding: 20px;
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
}
@media (max-width: 1100px) {
    .max_limit {
        grid-template-columns: 1fr;
    }
    .max_limit_right {
        max-width: 20vw;
        transform: translateY(-50px);
    }
}
.max_limit_left {
    width: 100%;
    height: 100%;
}
.max_limit_left >h3 {
    margin-bottom: 0;
    white-space: nowrap;
}
.max_limit_right {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 700px) {
    .max_limit_right {
        display: block;
        min-height: 250px;
    }
}
@media (max-width: 450px) {
    .max_limit_right {
        margin-left: 0;
    }
}
.doughnut_text_box {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 700px) {
    .doughnut_text_box {
        width: 200px;
        transform: translateY(-15px);
    }
}
.doughnut_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
@media (max-width: 700px) {
    .doughnut_wrapper {
        height: 250px;
        width: 200px;
    }
}
.doughnut_text {
    width: 105px;
    height: 105px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 1px 30px rgba(53,70,171,0.14);
    box-shadow: 0px 1px 30px rgba(53,70,171,0.14);
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    color: #767493;
}
.doughnut_number {
    font-size: 18px;
    color: #181143;
    font-weight: 700;
}
.bottom_rule {
    margin-left: 3px;
    border: 0px;
    border-bottom: 0.5px solid #dbd7d7;
    width: 94px;
}
.max_limit_count {
    margin-top: -7px;
    font-size: 22px;
    font-weight: 700;
}
.max_limit_nav_container {
    position: absolute;
    left: 20px;
    bottom: 15px;
}
.max_limit_row {
    margin-bottom: 1.5px;
    height: 24px;
}
.max_limit_dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FF6E42;
    margin-right: 7px;
}
.max_limit_row > span {
    font-size: 12px;
    padding-left: 7px;
    font-weight: 600;
}

.gray {
    background-color: #09CE8A;
}
.ocean {
    background-color: #CDE1EB;
}
