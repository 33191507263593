.filter_date_box {
    position: relative;
    width: 100%;
    min-width: 100px;
    max-width: 350px;
    background-color: white;
    height: 50px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.filtering_item {
    margin-bottom: 20px;
    margin-right: 10px;
}

.filter_date_space {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filter_date_space > p {
    font-size: 12px;
    color: #0E0A40;
}
#arrow_svg {
    fill: #CCCCCC;
}
.filter_date_drop {
    position: absolute;
    top: 55px;
    right: 0;
    width: 320px;
    min-height: 200px;
    background-color: white;
    z-index: 2;
    border-radius: 3px;
    padding-bottom: 10px;
}

@media (max-width: 920px) {
    .filter_date_drop {
        top: 55px;
        left: 0;
    }
}

.filter_date_btn_box {
    margin-top: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filter_date_btn_box > button:last-child {
    margin-left: 10px;
}

.filter_date_clear_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.filter_date_clear_btn > p {
    margin-bottom: 0;
    cursor: pointer;
    font-size: 14px;
    color: #0E0A40;
    text-decoration: underline;
}
